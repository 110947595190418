<div id="main_content">
	<app-toolbar [primaryButton]="'back'" title="Ops!"></app-toolbar>

	<div class="page_container_small pt-6">
		<h2 class="m_t_title text-center">Ops. Non ho trovato questa pagina</h2>

		<div class="mt-3 text-center">
			<button class="button-unstyled d-block" (click)="goHome()"><a class="m_t_hint">Torna alla Home</a></button>
			<button (click)="goHome()" mat-icon-button color="primary">
				<mat-icon style="width: 36px; height: 36px; font-size: 36px">home</mat-icon>
			</button>
		</div>
	</div>
</div>
